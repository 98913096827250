import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from "@components/layout";
import "./style.css";
import PropTypes from "prop-types";
import BreadcrumbText from "@components/common/BreadcrumbText";

const InsuranceContracts = props => {
  const data = useStaticQuery(graphql`
    query {
      allWpPage(filter: {slug: {eq: "insurancecontracts"}}) {
        nodes {
          title
          acfPageMeta {            
            metaTitle            
          }
          acfInsuranceContracts {
            subtitle
            titlecontracts
            nombrePlan
            info
            contracts {
              namecon
              imgcon {
                sourceUrl
                altText
              }
              url
            }
          }
        }
      }
    }
  `);

  const page = data.allWpPage.nodes[0];
  const breadcrumbLink2 = {
    url: "insurancecontracts",
    title: page.acfPageMeta.metaTitle,
  };

  return (
    <Layout>
      <div className="containercontratosaseguradoras">
        <div className="page-wrap">
          <div className="breadcrumbdorado">
            <div className="simple-breadcrumb-hero__breadcrumbs">
              <BreadcrumbText
                link1={{ url: "/", title: "Comienzo"}}
                pageTitle={page.title}
              />
            </div>
          </div>
          <h1 className="heading-text--xxl">{page.title}</h1>
          <h2>{page.acfInsuranceContracts.subtitle}</h2>
          <h3>{page.acfInsuranceContracts.titlecontracts}</h3>
          <div className="containercontratos row">
            {page.acfInsuranceContracts.contracts.map((contract, index) => (
              <div key={index} className="col-md-4 col-12">
                {/* Validar si la URL del contrato no está vacía o nula */}
                {contract.url && (
                  <a href={contract.url} target="_blank" className="cbox row">
                    <div className="col-md-5 col-5 cboximg p-0d">
                      <img src={contract.imgcon.sourceUrl} alt={contract.imgcon.altText} />
                    </div>
                    <div className="col-md-7 col-7">
                      <p className="pbold tplan">{page.acfInsuranceContracts.nombrePlan}</p>
                      <p className="pbold nplan">{contract.namecon}</p>
                    </div>
                  </a>
                )}
                {/* Si la URL está vacía o nula, renderizar solo el contenido del contrato sin enlace */}
                {!contract.url && (
                  <div className="cbox row">
                    <div className="col-md-5 col-5 cboximg p-0d">
                      <img src={contract.imgcon.sourceUrl} alt={contract.imgcon.altText} />
                    </div>
                    <div className="col-md-7 col-7">
                      <p className="pbold tplan">{page.acfInsuranceContracts.nombrePlan}</p>
                      <p className="pbold nplan">{contract.namecon}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="infoinsurance" dangerouslySetInnerHTML={{ __html: page.acfInsuranceContracts.info }} />
        </div>
      </div>
    </Layout>
  );
};

InsuranceContracts.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.shape({
    pages: PropTypes.array,
    directoryUri: PropTypes.string,
  }),
}
InsuranceContracts.defaultProps = {
  pageContext: {
    pages: [],
  },
}

export default InsuranceContracts;
